<template lang="pug">
t-window(v-bind="$attrs" v-on="$listeners")
  template(slot="title") Чек #120
  template(slot="footer"): t-btn(@click="$emit('input',false)" hero) Отправить

  .d-flex.fill-height.justify-center
    .check.ma-4.py-5.align-self-center
      pre.px-4.py-2
        center
          h3(v-if="buyerContact.name != group.name") {{group.name}}
          | {{ sale.buyList.contact.name | pad(17) }} {{ 'БИН '+sale.buyList.contact.bin | lpad('',18) }}
          | {{ sale.buyList.contact.address }}
        |--------------------------------------
        center
          |{{sale.meta_.accounted | datetime }}
        | {{ ( sale.meta_.owner || 'Кассир 1') | shortFio | lpad('Документ №'+sale.meta_.code)}}
        | {{ currentKKM.name | lpad('Касса:') }}
        | {{(sale.payList.contact?(sale.payList.contact.name?sale.payList.contact.name:sale.payList.contact):'Не указан') | lpad('Клиент:')}}{{'\n'}}
        template(v-if="sale.payList.contact && sale.payList.contact.code_") {{sale.payList.contact.code_ | cols('Тел:')}}{{'\n'}}
        //- |
        | --------------------------------------
        |
        template(v-if="sale.buyList.items")
          template(v-for="(i, index) in sale.buyList.items")
            | {{ i.amount | moneyz | lpad(i.name)}}{{'\n'}}
            template(v-if="i.quantity && i.quantity > 1")     {{i.quantity}} x {{i.price | moneyz}} {{'\n'}}
            template(v-if="i.discount")     Скидка {{i.discount}} {{'\n'}}
        |
        template(v-if="sale.payList.items")
          |-------------------------------------
          |
          template(v-for="(i, index) in sale.payList.items" v-if=" i.amount > 0")
            | {{ i.amount | moneyz | cols(i.name+':')}}{{'\n'}}
          template(v-if="sale.payList.excess!=0")
            | {{ sale.payList.excess | moneyz | cols(sale.payList.excess &gt; 0?'Сдача:':'Недоимка:')}}{{'\n'}}
        |
        b {{ sale.buyList.total | moneyz | cols('ИТОГО:')}}{{'\n'}}
        //- |
      pre.pa-4.fiscal(:class="fiscalStatus")
        template(v-if="fiscalStatus == 'pending'")
          |
          b ========= ФИСКАЛИЗАЦИЯ ЧЕКА ==========
          |
        template(v-else)
          .graph
            | ╔════════ ФИСКАЛЬНЫЙ ЧЕК ФП ═════════╗
            | ║ {{fiscal.created | strndate | lpad('Дата',16) }}   {{fiscal.created | strtime | lpad('Время',15) }} ║
            | ║ {{fiscal.code | lpad('Фиск.признак'+(fiscal.isOffline?' (автономный)':''),34)}} ║
            | ║ {{fiscal.rnk | cols('КОД ККМ КГД / РНК:',18,16)}} ║
            | ║ {{fiscal.znk | cols('ЗНК:',18,16)}} ║
            template(v-if="!fiscal.ofd")
              |
              | ╚════════════════════════════════════╝
            template(v-else)
              |
              | ╠═══════╦════════════════════════════╣
              | ║ BINGO ║ {{fiscal.ink | lpad('ИНK:',26)}} ║
              | ║ 65554 ║ {{fiscal.ofd | lpad('ОФД:',26)}} ║
              | ╚═══════╩════════════════════════════╝
          template(v-if="fiscal.ofd")
            center
              b Сохрани чек в Amian :
            |      Сайт : amian.kz/{{fiscal.codeToken}}
            //- |  Telegram : t.me/CheckGovKzBot?start={{fiscal.codeToken}}
            |  Telegram : @AmianBot {{fiscal.codeToken}}
            //- | Тел(USSD) : *1414*5*{{fiscal.codeToken}}#
            //- |       Тел : 1414,5{{fiscal.codeToken}}#

      pre.py-2
        center
          | *** СПАСИБО ЗА ПОКУПКУ ***
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    color: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    showDialog: false,
    show: false,
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,

    currentKKM: {
      name: 'Касса 1',
    },
    buyerContact: {
      name: 'ИП Иванько',
    },
    group: {
      name: 'Касса 1',
    },
    fiscalStatus: 'pending',
    sale: {
      no: 151,
      cmd: 'SALE',
      meta_: {
        created: new Date(),
        accounted: new Date(),
        code: 'JKL',
        owner: 'Алина Кузьменко',
      },
      buyList: {
        total: 1000,
        contact: {
          name: 'Ерик Турысов',
          bin: '5654654654',
        },
        items: [
          {
            amount: 1000,
            name: 'Молоко',
            quantity: 2,
            price: 2000,
            discount: null,
          },
          {
            amount: 2000,
            name: 'Конфеты',
            quantity: 1,
            price: 5000,
            discount: '10 %',
          },
        ],
      },
      payList: {
        total: 2000,
        excess: 50,
        contact: {
          name: 'Алина Кузьменко',
          code_: '+77779003000',
        },
        items: [
          {
            amount: 500,
            name: 'Наличными',
            excess: 0,
          },
        ],
      },
    },
    fiscal: {
      created: new Date(),
      ofd: 'КГДРК(БЛОКЧЕЙН)',
      code: Math.ceil(Math.random() * 100000),
      codeToken: Math.ceil(Math.random() * 100),
      isOffline: (Math.random() * 100 > 50),
      ink: 655855115287,
      rnk: 655855115287,
      znk: 'SWK00031019',
    },
  }),
  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
  },
}
</script>
<style lang="stylus">

.check
  // margin 40px auto
  font-size 0.8rem
  color black
  box-shadow: 0 15px 15px rgba(0,0,0,0.2);
  position: relative;
  background: #fff;
  border-radius 5px 5px 0 0

  .graph
    line-height 0.9rem

  pre
    font-family 'Cons','Menlo', monospace !important

    &.pending
      -webkit-animation: 0.5s blink step-end infinite;

    &.success
      -webkit-animation: 1s appear linear;

  pre.fiscal
    background-color rgba(0,123,255,0.1)

  /* torn edges */
/*.check:after,*/
.check:before {
    content: "";
    display: block;
    position: absolute;
    left:0;
    right:0;
    height: 5px;
    z-index: 1;
    /* TODO Add browser prefixes */
    /*background: linear-gradient(-45deg, #f7f7f7 16px, transparent 0), linear-gradient(45deg, #f7f7f7 16px, transparent 0);*/
    background:
    linear-gradient(
      45deg, transparent 33.333%,
      #fff 33.333%, #fff 66.667%,
      transparent 66.667%
    ),
    linear-gradient(
      -45deg, transparent 33.333%,
      #fff 33.333%, #fff 66.667%,
      transparent 66.667%
    );
    background-size: 5px 10px;
    background-position: 0 -5px;
}
/*
.check:after {
    top: -10px;
    transform:rotate(180deg);
}*/

.check:before
    bottom -5px

.check dw, .check .dw { /*DOUBLE WIDTH emulation */
    transform:scaleX(2);
    transform-origin: top left;
}
.check right dw { /*DOUBLE WIDTH emulation */
    transform-origin: top right;
}
.check center dw { /*DOUBLE WIDTH emulation */
    transform-origin: top center;
}
.check dh,.check .dh  { /*DOUBLE HEIGHT emulation */
    transform:scaleY(2);
    display: block;
}

.check right {
  display: inline-block;
  text-align: right;
  width: 100%;
}

.check center {
  text-align: center;
}

.check barcode:after {
      display: block;
    line-height: 50px;
      font-size: 50px;
      color: #000;
      content: attr(value);
      font-family: 'Code39';
  }

</style>
