<template lang="pug">
AppLayout
  template(v-slot:rightdrawer)
    v-navigation-drawer(app, clipped, permanent, right, absolute, width="340")
      Cart(@input="clearCart", @route="dialogRoute")

  v-card.px-2
    Search.ml-1(@scan="dialogRoute('scan')")
    NavCategories
  Catalog(@edit="show.form = true" :gridCount="24")
  template(v-slot:dialogs)
    Form(v-model="show.form", @route="dialogRoute", dialog)
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'

import Form from 'T@/components/catalog/Form.vue'
import Catalog from 'T@/components/catalog/Catalog.vue'
import Cart from 'T@/components/cart/Cart.vue'

import Search from 'T@/components/catalog/Search.vue'
import NavCategories from 'T@/components/catalog/NavCategories.vue'

export default {
  name: 'KassaPOSLayout',
  components: {
    AppLayout,
    Form,
    Cart,
    Catalog,
    Search,
    NavCategories,
  },

  data: () => ({
    route: null,
    show: {
      categories: false,
      categories1: false,
      catalog: false,
      checkout: false,
      scan: false,
      cart: false,
      form: false,
      form2: false,
      check: false,
      payment: false,
      kaspi: false,
      client: false,
      contacts: false,
    },
  }),

  computed: {
    ...mapState({
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
    }),
    dialogRoute(route, autoClose) {
      console.log('dialogRoute', route, autoClose, this.route)
      if (autoClose && this.route) {
        this.show[this.route] = false
      }
      this.show[route] = true
      this.route = route
    },
  },
}
</script>
<style lang="stylus"></style>
