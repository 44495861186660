<template lang="pug">
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  template(slot="header") Чек #120
  template(slot="header-actions-prepend")
    t-btn.mr-1(v-if="!s.implicitClient"  @click.stop="emit('route','contacts')" fab icon) mdi-account-plus
  template(slot="header-more" v-if="!s.implicitClient || s.trashOrOptions")
    CartOptions(v-model="s")

  template(v-slot:prebody-bold)
    .blinker(v-if="loading") Обработка Оплаты ...
    template(v-else) Сдача 500 ₸

  template(slot="subheader")
    CartClientActions(v-on="$listeners" :implicitClient="s.implicitClient")
    v-progress-linear(:indeterminate="loading" background-color="grey darken-2" color="primary" height="3" :active="true" :value="0")
    t-alert(v-model="warning")
      span.mr-1.font-weight-bold Смена 1
      | Автоматический открыта,
      br
      | Сумма в Кассе
      span.ml-1.font-weight-bold 1000 ₸

  t-collapse(:value="!loading" hide)
    v-list.mb-5.pt-0
      v-subheader Отправить чек
      v-list-item.mt-2
        t-btn(block @click="emit('route','check')") Печать Чека
      v-list-item.mt-2
        t-btn(block @click="loading = !loading;") Whatsapp Чек
      v-list-item.mt-2
        t-btn(block disabled) SMS Чека

  template(slot="footer")
    t-btn(hero :disabled="loading" @click="$emit('input',false)") ГОТОВО
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

import Basket from 'T@/components/cart/Basket.vue'
import CartClientActions from 'T@/components/cart/_parts/CartClientActions.vue'
import CartOptions from 'T@/components/cart/_parts/CartOptions.vue'
import DialogPaymentActions from 'T@/pages/kassa/DialogPaymentActions.vue'

export default {
  name: 'PaymentProgress',
  components: {
    Basket,
    CartClientActions,
    DialogPaymentActions,
    CartOptions,
  },
  props: {
    item: {
      type: Object,
      default: null,
      required: false,
    },
    color: {
      type: String,
      default: undefined,
    },
  },

  data: () => ({
    s: {
      implicitClient: false,
    },
    warning: false,
    loading: false,
  }),
  computed: {
    ...mapGetters({
      cart: 'tjtheme/cart',
      totalSum: 'tjtheme/total',
    }),
  },
  watch: {
    '$attrs.value': function (v) {
      if (v) this.loading = true
    },
    loading(v) {
      if (v) {
        this.warning = false
        setTimeout(() => {
          this.loading = false
          this.warning = true
        }, 1000)
      }
    },
  },
  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
    }),
    emit(e, param) {
      this.$reset()
      this.$emit(e, param)
    },
  },
}
</script>
<style lang="stylus"></style>
