<template lang="pug">
iframe.webview(
  style='width: 100%; overflow: hidden; background-color: #fff; border: none',
  allowtransparency='false',
  :src='ip + url'
)
</template>
<script>
export default {
  props: ['url'],
  components: {},
  data() {
    return {
      // ip: 'http://127.0.0.1:3000/_comrun/v-rekassa3/src/TJTheme/pages/apps/taxpassport/Taxpassport.html',
      ip: '//taxpassport.apps.rekassa.kz/',
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMsg, false)
  },
  methods: {
    receiveMsg(p) {
      if (p.origin === 'http://127.0.0.1:3000' && p.data.uuid) {
        console.log('uuid', p.data.uuid)
        this.$emit('message', p.data)
      }
    },
  },
}
</script>
<style lang="stylus">
.t-webview .t-header
  right auto !important
.webview
  position absolute
  top -47px
  height 100vh
</style>
