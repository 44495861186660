<template lang="pug">
v-row(dense)
  v-row(dense)
    //- v-col.pt-0(cols="12")
      v-divider.item-divider
    v-col(cols="6")
      v-select(:label="$t('тип')" :dense="dense" outlined v-model="discountMarkupType"  hide-details="auto" :items="discountMarkupTypes" item-value="value" item-text="title")
    v-col(cols="6")
      v-text-field(:label="$t('discountMarkupType.' + discountMarkupType)" hide-details="auto" outlined :dense="dense" :suffix="suffix" v-model.number="discountMarkupValue" type="number" @keypress="limitDecimal($event, discountMarkupValue, 2)" :rules="[rules.numberRange(0, 100000000, discountMarkupValue)]" validate-on-blur autocomplete="off")

    v-col(cols="12")
      //- v-divider.item-divider
    v-col(cols="6")
      v-select(:label="$t('ставка_ндс')" :dense="dense" outlined v-model="taxType" hide-details="auto" :items="taxTypes")
    v-col(cols="6")
      v-text-field(:label="$t('в_том_числе_ндс')" :dense="dense" hide-details="auto" :value="`${taxType >= 0 ? $options.filters.numeral(tax, '0,0.[00]') : '-'}`" suffix="₸" disabled outlined)
    v-col(cols="12")
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Decimal } from 'decimal.js'
import i18n from '../../../../i18n/index'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: Boolean,
      default: false,
      required: false,
    },
    dense: {
      type: Boolean,
      default: undefined,
    },
  },

  data: () => ({
    unitTypes: ['шт', 'кг'],
    showDialog: false,
    editMode: false,
    discountMarkupTypes: [{
      title: 'Скидка НГ',
      value: '123',
    },

    ],
    expand: false,
    isSklad: false,
    total: 1000,
    name: 'Молоко',
    exciseStamp: null,
    quantity: 2,
    unitType: 'PIECE',
    price: 1000,
    tax: 10,
    taxType: 12,
    taxTypes: ['На все', 'На позицию'],
    discountMarkupType: 'DISCOUNT_PERCENTAGE',
    discountMarkupValue: 0,
    suffix: '%',
    positionsDialog: false,
    configuration: {
      taxMode: false,
    },
    preferences: {},
    positionsLoading: false,

  }),

  computed: {
    ...mapState({
      // positionsLoading: state => state.positions.loading,
      // preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      // configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
    scanDataMatrix() {},
    increaseQuantity() {},
    decreaseQuantity() {},
    addItem() {},
    saveItem() {},
    deleteItem() {},

  },
}
</script>
<style lang="stylus">

</style>
