<template lang="pug">
//- v-item-group.text-center.d-flex(:value='value' mandatory)
  v-item(v-slot='{ active, toggle }')
    v-btn(:input-value='active' x-small icon @click='toggle')
      v-icon mdi-record

v-tabs.nav(:value='value' @change="$emit('input',$event)"
  Zv-model="value"
  Zfixed-tabs
  centered
  Zgrow
  optional
  style="margin-top:-40px"
  height="18"
  dark)
  //- v-tab(href="#menu"): v-icon mdi-menu
  //- v-tab(href="#home"): v-icon mdi-home
  //- v-tab(href="#catalog"): v-icon mdi-view-dashboard

  v-tab.px-0(href="#home"): v-icon mdi-record
  v-tab.px-0(href="#catalog"): v-icon mdi-record
  v-tab.px-0(href="#catalog2"): v-icon mdi-record
  v-tab.px-0(href="#catalog3"): v-icon mdi-record
  v-tab.px-0(href="#list"): v-icon mdi-record
  v-tab.px-0(href="#check"): v-icon mdi-home

  //- v-tab.px-0(href="#catalog"): v-icon mdi-record

  //- v-tab(href="#form"): v-icon mdi-card-text-outline
  //- v-tab(href="#calc"): v-icon mdi-calculator
  //- v-tab(href='#tab-4')(href="#item.value"): v-icon mdi-basket
  //- v-tab(href="#orders"): v-icon mdi-history
  //- v-tab(href='#tab-1'): v-icon mdi-view-week
</template>
<script>
export default {
  components: {},
  props: ['value'],
  methods: {},
}
</script>
<style lang="stylus">
.nav .v-tab .v-icon
  font-size 14px
</style>
