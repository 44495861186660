<template lang="pug">
AppLayout.t-webview(app-bar-color="transparent")
  template(v-slot:header)
    | &nbsp;
  webview(:url='"?embeded=menu&dark=yes&bin=910515451194&phone=77771234567&znk=Z11L1RRH-MBX&uuid="+uuid', @message='message')
</template>
<script>
// import { mapGetters, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'
import Webview from './Webview.vue'
// import TaxPassport from './Taxpassport.vue'

export default {
  components: {
    // TaxPassport,
    Webview,
    AppLayout,
  },
  data() {
    return {
      uuid: localStorage.getItem('app-taxpassport-uuid'),
    }
  },
  mounted() {},
  methods: {
    message(data) {
      console.log('uuid', data.uuid)
      localStorage.setItem('app-taxpassport-uuid', data.uuid)
      this.uuid = data.uuid
    },
  },
}
</script>
<style lang="stylus"></style>
