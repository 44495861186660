<template lang="pug">
AppLayout(Zstyle="margin: 0 auto; min-width: 1000px; max-width: 1200px")
  v-row.pa-3.fill-height(no-gutters, style="height: calc(100vh - 48px)")
    v-col(cols="8")
      v-card.px-2
        Search.ml-1(@scan="dialogRoute('scan')")
        NavCategories
      Catalog( :gridCount="24")
    v-col.pl-3.fill-height(cols="4")(Zstyle="max-width:340px;")
      Cart(@input="clearCart")
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AppLayout from 'T@/AppLayout.vue'

import Search from 'T@/components/catalog/Search.vue'
import NavCategories from 'T@/components/catalog/NavCategories.vue'

export default {
  name: 'KassaPOSLayout',
  components: {
    AppLayout,
    // Basket: () => import('T@/components/cart/Basket.vue'),
    Cart: () => import('T@/components/cart/Cart.vue'),
    Catalog: () => import('T@/components/catalog/Catalog.vue'),
    NavCategories,
    Search,
  },

  data: () => ({
    showDialog: false,
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),

  computed: {
    ...mapState({
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
      clearCart: 'tjtheme/clearCart',
    }),
    dialogRoute(route, autoClose) {
      // console.log('dialogRoute', route, autoClose, this.route)
      // if (autoClose && this.route) {
      //   this.show[this.route] = false
      // }
      // this.show[route] = true
      // this.route = route
    },
  },
}
</script>
<style lang="stylus"></style>
