<template lang="pug">
t-window(v-bind="$attrs", shaped, v-on="$listeners", Zmin-height="200px")
  template(slot="title") {{ item.name }}
  template(slot="header-container")
    //- v-card-text(@click.stop="$emit('maximize')")
    //- v-img.white--text(Zcontain height="150" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)" src="https://cdn.vuetifyjs.com/images/cards/cooking.png")
    v-img.white--text(
      Zcontain,
      height="150",
      gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)",
      src="/catalog/icecreamcook.jpg"
    )
      .d-flex.justify-space-between.fill-height
        v-card-title.align-end
          .headline {{ item.name ? item.name : 'Позиция' }}
          //- v-row
            v-col: v-icon mdi-camera
        .ma-3
          v-btn.mr-1(color="grey darken-3",fab,depressed,x-small,Zicon,dark,@click.stop="panel = panel === 0 ? null : 0")
            v-icon(color="white") mdi-pencil
          v-btn.mr-1(
            color="grey darken-3",
            Ztext,
            fab,
            depressed,
            x-small,
            Zicon,
            dark,
            @click.stop="panel = panel === 1 ? null : 1"
          )
            v-icon(color="white") mdi-sale

          v-btn.ml-4(
            color="grey darken-3",
            fab,
            depressed,
            x-small,
            Zicon,
            dark,
            @click="$emit('input', false)"
          )
            v-icon(color="white") mdi-close
          //- v-btn(color="grey darken-3" Ztext fab depressed x-small Zicon dark @click.stop="$emit('close')")

          v-menu
            template(v-slot:activator="{ on, attrs }")
              v-icon.ml-2(color="white"  v-bind="attrs", v-on="on") mdi-dots-vertical
            CartOptions(v-model="settings")
              t-list-item(title="Enable Picture")
                v-switch(inset v-model="settings.showPic")

  template(slot="footer")
    //- transition(name="slide-up" mode="out-in")
    //-   v-btn(v-if="item.realQuantity == 0" @click="item.realQuantity = 1") Edit
    //-   v-btn(v-else @click="item.realQuantity = 0") Save
    transition(:name="item.realQuantity == 0 ? 'basketIn' : 'basketOut'" mode="out-in" Zduration="2000")
      t-btn(v-if="item.realQuantity == 0" @click="item.realQuantity = 1" hero) Добавить в чек
      Basket(v-else @click="addItemDetached(item)"
        no-menu,
        no-cart,
        :total="item.total"
        label="ИТОГО"
        color="indigo"
        Zcart-icon="left"
        @cart="$emit('route', 'cart', true);"
        :empty="!item.total"
      )
        template(slot="left-content" v-if="item.realQuantity != 0" slot-scope="{isEmpty,disabled}")
          v-btn(color="grey" x-large depressed Zoutlined @click="item.realQuantity--"): v-icon mdi-minus
          v-btn(color="indigo" x-large depressed Zoutlined @click="item.realQuantity++"): v-icon mdi-plus
        //- div Перейти в чек

    //- Basket(@click="item.realQuantity == 0? item.realQuantity = 1: addItemDetached(item)"
    //-   no-menu,
    //-   no-cart,
    //-   color="indigo"
    //-   Zcart-icon="left"
    //-   @cart="$emit('route', 'cart', true);"
    //-   :empty="!item.total"
    //- )
    //-   template(slot="left-content" v-if="item.realQuantity != 0" slot-scope="{isEmpty,disabled}")
    //-     v-btn(color="grey" x-large depressed Zoutlined @click="item.realQuantity--"): v-icon mdi-minus
    //-     v-btn(color="primary" x-large depressed Zoutlined @click="item.realQuantity++"): v-icon mdi-plus
    //-   div Перейти в чек
      //- .font-weight-regular(v-if="item.total") Итого {{item.total}}

    //- v-btn(v-if="editMode" text color="red" @click="deleteItem()") {{ $t('удалить') }}
    //- v-btn(v-if="editMode" text color="primary" @click="saveItem()") {{ $t('сохранить') }}

  //- template(v-slot:header)
    v-toolbar(dense flat dark v-if="$vuetify.breakpoint.xsOnly")
      v-spacer
      v-toolbar-title {{ editMode ? $t("редактирование") : $t("новая_позиция") }}
      v-spacer

  v-form(ref="form")
    v-expansion-panels(Zaccordion, dense, flat, v-model="panel")
      v-expansion-panel.transparent
        v-expansion-panel-header.px-4.grey--text Наименование
          //- template(v-slot:default="{ open }") Налоги и Скидки
        v-expansion-panel-content.pa-0
          v-row(dense)
            v-row(dense)
              v-col(cols="6")
                v-text-field(
                  :label="$t('SKU Штрихкод')",
                  v-model="item.barcode",
                  clearable,
                  outlined,
                  validate-on-blur,
                  autocomplete="off",
                  :dense="dense",
                  hide-details,
                  placeholder=" "
                )
                  template(Zv-if="$isCordova()", v-slot:append)
                    v-icon mdi-barcode
              v-col(cols="6")
                v-text-field(
                  :label="$t('ID Маркировка')",
                  v-model="item.exciseStamp",
                  clearable,
                  outlined,
                  validate-on-blur,
                  autocomplete="off",
                  :dense="dense",
                  hide-details,
                  placeholder=" "
                )
                  template(Zv-if="$isCordova()", v-slot:append)
                    v-icon mdi-qrcode-scan
              v-col(cols="12")
              v-col(cols="12")
                v-textarea(
                  label="Наименование",
                  v-model="item.name",
                  Zsingle-line,
                  Zreadonly="!editMode",
                  maxlength="500",
                  dense,
                  outlined,
                  rows="3",
                  hide-details,
                  clearable,
                  auto-grow,
                  autocomplete="off",
                  placeholder="Позиция 1"
                )
                  //- template(v-slot:append)
                    //- v-icon mdi-format-list-bulleted
                    v-icon mdi-barcode
      v-expansion-panel.transparent
        v-expansion-panel-header.px-4.grey--text Скидки и Налоги
          //- template(v-slot:default="{ open }") Налоги и Скидки
        v-expansion-panel-content
          FormDiscount(:dense="dense")

    v-container.py-0
      v-row(dense)
        v-col(cols="12")
          v-alert.my-1(
            v-model="dialogAlert",
            type="error",
            transition="fade-transition",
            text,
            dense,
            dismissible
          ) Остаток товара превышен

        v-col.mb-3(cols="6")
          v-menu(light, Zoffset-y, Zoffset-overflow)
            v-list
              v-list-item(
                v-for="u in unitTypes",
                @click="item.unitType = u.value"
                :key="u.title"
              ): v-list-item-title {{ u.title }}
            template(v-slot:activator="{ on, attrs }")
              v-text-field.tright(
                :label="$t('количество')",
                Zcounter="skladAmount",
                :dense="dense",
                hide-details,
                :error="skladAlert",
                :prefix="'Склад '+(skladAmount - item.realQuantity)"
                outlined,
                inputmode="numeric" pattern="[0-9]*"
                :suffix="$t('unitType.' + item.unitType)",
                v-model.number="item.realQuantity",
                type="number",
                @click:append="showUnitTypes = true",
                autocomplete="off"
                )
                //- template(v-slot:counter): .caption Остаток {{ item.realQuantity }} / {{ skladAmount }}
                template(v-slot:append)
                  v-icon(v-bind="attrs", v-on="on") mdi-menu-down

              //- v-text-field.tcenter(
              //-   :label="$t('количество')",
              //-   :counter="skladAmount",
              //-   :dense="dense",
              //-   hide-details="auto",
              //-   Zkeydown="onQuantity",
              //-   :error="skladAlert",
              //-   outlined,
              //-   inputmode="numeric" pattern="[0-9]*"
              //-   :suffix="$t('unitType.' + item.unitType)",
              //-   v-model.number="item.realQuantity",
              //-   prepend-inner-icon="mdi-minus",
              //-   @click:prepend-inner="item.realQuantity--",
              //-   append-icon="mdi-plus",
              //-   type="number",
              //-   @click:append="showUnitTypes = true",
              //-   autocomplete="off"
              //- )
              //-   template(v-slot:counter): .caption Остаток {{ item.realQuantity }} / {{ skladAmount }}
              //-   template(v-slot:append)
              //-     v-btn(
              //-       Zfab,
              //-       depressed,
              //-       Zcolor="primary",
              //-       icon,
              //-       x-small,
              //-       @click="item.realQuantity++"
              //-     ): v-icon mdi-plus
              //-     //- v-icon(@click.stop="item.realQuantity--" :disabled="item.realQuantity <= 1") mdi-minus
              //-     //- v-icon(@click.stop="item.realQuantity++") mdi-plus
              //-     v-icon(v-bind="attrs", v-on="on") mdi-menu-down
              //-     //- v-chip(v-bind="attrs" v-on="on" small) {{$t('unitType.'+item.unitType)}}
                    v-icon.ml-0(right) mdi-menu-down

                //- template(v-slot:prepend)
                  //- v-icon(@click.stop="$emit('showCalc')") mdi-minus
                  //- v-btn(fab depressed color="primary"  :disabled="realQuantity <= 1" x-small @click="realQuantity--")
                  v-icon(@click.stop="item.realQuantity--" :disabled="item.realQuantity <= 1") mdi-minus

        v-col(cols="6")
          v-text-field.tright(
            :label="$t('Цена') + ' за 1 шт'",
            :readonly="!editMode",
            Zcounter="item.realQuantity != 1",
            hide-details,
            outlined,
            inputmode="numeric" pattern="[0-9]*"
            :dense="dense",
            prepend-inner-icon="mdi-calculator-variant",
            v-model.number="item.price",
            @click:prepend-inner="$emit('route','calc',true)",
            hide-spin-buttons,
            type="number",
            suffix="₸",
            autocomplete="off"
          )
            //- template(v-slot:counter): .caption Итого {{ item.total }} ₸

        //- v-col(cols="3")
          v-select(v-model="item.unitType" dense :display="$t('unitType.'+item.unitType)" hide-details="hide" outlined :items="unitTypes" item-value="value" item-text="title")
</template>
<script>
import { mapState, mapActions } from 'vuex'

import FormDiscount from 'T@/components/catalog/_parts/FormDiscount.vue'
import Basket from 'T@/components/cart/Basket.vue'
import CartOptions from 'T@/components/cart/_parts/CartOptions.vue'

// const HelloWorld = {
//   props: ['msg'],
//   template: `<div>
//     <h1>Hello world</h1>
//     <div>{{ this.msg }}</div>
//   </div>`
// };

export default {
  name: 'Form2',
  components: {
    FormDiscount,
    Basket,
    CartOptions,
  },
  props: {
    dense: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({
    basketTransition: 'basketIn',
    isAdded: false,
    unitTypes: [
      { value: 'PIECE', title: 'шт' },
      { value: 'KILOGRAM', title: 'кг' },
    ],
    panel: null,
    showUnitTypes: true,
    skladAlert: false,
    dialogAlert: false,
    unitTypeMenu: false,
    editMode: false,
    discountMarkupTypes: [
      {
        title: 'Скидка НГ',
        value: '123',
      },
    ],
    item: {
      barcode: 1000,
      exciseStamp: 'PO6X54654',
      name: 'Терамису',
      price: 1000,
      realQuantity: 0,
      unitType: 'PIECE',
      discountValue: 0,
      markupValue: 0,
      markupType: 'шт',
      markupPercentage: 1,
      total: 1000,
      taxes: [{ sum: { value: 0 }, percent: 0 }],
    },
    // expand: false,
    // isSklad: false,
    skladAmount: 5,
    tax: 10,
    taxType: 12,
    taxTypes: ['На все', 'На позицию'],
    discountMarkupType: 'DISCOUNT_PERCENTAGE',
    discountMarkupValue: 0,
    suffix: '%',
    configuration: {
      taxMode: false,
    },
    settings: {
      showTotalLine: true,
      showPic: true,
      goToCart: true,
      fullscreen: false,
      ZIStyle: false,
    },
  }),

  computed: {
    ...mapState({
      // cart: (state) => state.tjtheme.cart,
    }),
  },

  watch: {
    'item.realQuantity': {
      immediate: true,
      handler(v) {
        // eslint-disable-next-line no-multi-assign
        this.skladAlert = this.dialogAlert = v > this.skladAmount
        this.item.total = v * this.item.price
      },
    },
  },

  methods: {
    ...mapActions({
      addItem: 'tjtheme/addItem',
    }),
    addItemDetached(item) {
      this.addItem({ ...item })
      this.$emit('input', false)
    },
    onQuantity(e) {
      if (!this.item.realQuantity) this.item.realQuantity = 1

      if (this.item.realQuantity > 99) {
        // eslint-disable-next-line no-alert
        // eslint-disable-next-line no-restricted-globals
        if (!confirm('Quantity more than 99, Are you sure ?')) {
          this.item.realQuantity = 5
        }
      }
    },
    scanDataMatrix() {},
  },
}
</script>
<style lang="stylus">
.v-text-field.tright input
  text-align end
.v-text-field.tcenter input
  text-align center
.v-expansion-panel-content__wrap
  padding 0 16px
 .basketIn-enter-active, .basketIn-leave-active, .basketOut-enter-active, .basketOut-leave-active
  transition transform 0.1s ease-out
.basketIn-enter, .basketOut-leave-to
  opacity 0.3
  transform translateX(50px)
.basketIn-leave-to, .basketOut-enter
  opacity 0.5
  transform translateX(-50px)
</style>
