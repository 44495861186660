<template lang="pug">
v-autocomplete.search(
  v-model="input"
  append-icon=""
  Zprepend-inner-icon="mdi-magnify"
  flat
  filled
  solo-inverted
  Zoutlined
  Zrounded
  dense
  clearable
  hide-details
  Zautofocus
  ref="autocomplete"
  :search-input.sync="search"
  :placeholder="$t('Поиск ...')"
  :items="items"
  item-text="name"
  return-object
  :hide-no-data="!search"
  :allow-overflow="false"
  :loading="loading"
  :disabled="loading"
  @change="change")
  template(v-slot:append-outer)
    v-icon(@click.stop="$emit('scan')") mdi-barcode-scan

  template(slot="item" slot-scope="{ parent, item }")
    v-list-item-content
      v-list-item-title Title
      v-list-item-subtitle {{ 1000 | numeral('0,0.[00]') }} ₸ ({{ $t('unitType.' + 'PIECE') }})
  //- template(slot="append")
    v-btn(icon color="primary"): v-icon mdi-barcode-scan
    //- v-icon(text color="primary" :loading="loading" @click="qrcode" name="md-barcode")
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {},

  // mixins: [decimalMixin, dictionaryMixin, billsAndCoinsMixin],

  props: {
    value: {
      type: String,
      default: '',
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    parent: {
      type: String,
      default: null,
      required: false,
    },
  },

  data: () => ({
    input: null,
    search: null,
    items: [],
    loading: false,
  }),

  computed: {
    ...mapState({
      positionsLoading: (state) => state.positions.loading,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
    change() {

    },
    qrcode() {

    },
  },
}
</script>
<style lang="stylus">

.theme--dark.v-text-field--solo-inverted > .v-input__control > .v-input__slot
  // background: rgba(0,0,0,0.12)
  background: transparent

.theme--dark.v-text-field--solo-inverted.v-input--is-focused > .v-input__control > .v-input__slot
  background: #fff

.search.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details
  padding 0
</style>
