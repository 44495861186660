<template lang="pug">
t-dialog#cartActions(:value="value" inset @input="$emit('input',$event)")
  v-list
    v-subheader Оплаты
    v-divider
    t-list-item(icon="messenger.png" title="Авто-фискализизация")
      v-checkbox(v-model="reverse" right)
    v-divider
    t-list-item(icon="mdi-close" @click="$emit('input',false)" title="Закрыть" noRight)
    //- v-list
      v-list-item
        v-list-item-avatar(Zrounded): v-icon.primary(dark) mdi-folder
        v-list-item-title Продажа
      v-list-item
        v-list-item-avatar(rounded): v-icon.primary(dark) mdi-folder
        v-list-item-title Возврат
</template>
<script>

export default {
  components: {
  },
  props: ['value'],
  data: () => ({
    bottom: false,
    reverse: false,
    actions: [
      {
        img: 'messenger.png', out: 'Продажа', in: 'Закупка', active: true,
      },
      // { img: 'keep.png', out: 'Возврат Продажи' },
      { img: 'inbox.png', in: 'Приход', out: 'Перемещение' },
      { img: 'hangouts.png', in: 'Инвентарь', out: 'Списание' },
      // { img: 'google.png',  },
    ],
  }),
}
</script>
<style lang="stylus">
</style>
