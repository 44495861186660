<template lang="pug">
t-btncard.catalogItem(v-on="$listeners" :selected="selected" skeleton="list-item-two-line" v-sound.snap)
  template(v-if="item")
    template(slot="icon")
      v-img(v-if="img" src="/logo/Rekassa_Hoodie2.png" contain)
      v-list-item.pl-2.pr-0(three-line)
        v-list-item-content.px-0
          v-list-item-title.d-flex.justify-space-between
              .title {{item.name}}
              v-icon.no-focus(color="primary darken-2" @click.stop="$emit('edit')") mdi-dots-vertical
          v-list-item-subtitle
            template(v-if="item.name && item.name.length > 20") {{item.name}}

    v-list-item.pl-0.pr-2.grow
      v-list-item-title
        .title.ezcar {{ item.price | numeral('0,0.[00]') }} ₸
      v-row(align="center" justify="end" v-if="selected && item.realQuantity > 0")
        v-chip.px-1(label color="transparent")
          span.pl-1 {{item.realQuantity}} шт
          v-btn(icon small  color="primary"): v-icon(@click.stop="item.realQuantity--" v-if="item.realQuantity >= 1") mdi-{{item.realQuantity == 1 ? 'trash-can' : 'minus-circle'}}
      //-   v-list-item-title.pl-2.d-flex.justify-space-between
      //-
      //-
      //-
      //-

</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'CatalogItem',
  components: {},
  props: {
    outlined: {
      type: Boolean,
      default: undefined,
    },
    item: {
      type: Object,
      default: null,
      required: false,
    },
    selected: {
      type: Boolean,
      default: undefined,
    },
    img: {
      type: Boolean,
      default: undefined,
    },
  },

  data: () => ({
    showDialog: false,
    quantity: 2,
  }),

  computed: {
    ...mapState({
    }),
  },
  methods: {
  },
}
</script>
<style lang="stylus">
// .catalogItem
//   .headline, .subtitle-1, .title
//     // max-width:85px
//     text-overflow:ellipsis
//     overflow:hidden
//     line-height 1em
.v-skeleton-loader__bone
  background: none !important;

.catalogItem
  min-height 200px
  border-width 4px !important

  .v-list-item--three-line
    min-height: 55px

  .v-list-item
    min-height: 32px;

  .headline, .subtitle-1, .title
    // max-width:85px
    text-overflow:ellipsis
    overflow:hidden
    line-height 1em

</style>
