<template lang="pug">
v-fab-transition
  v-btn.catalogAddBtn(v-show="value" color="primary" dark fixed Zabsolute bottom right fab @click="$emit('click')"): v-icon mdi-plus
</template>
<script>

export default {
  components: {},
  props: ['value'],
  methods: {
  },
}
</script>
<style lang="stylus">
.catalogAddBtn.v-btn--fixed.v-btn--bottom
    bottom calc( 80px + env(safe-area-inset-bottom))

.catalogAddBtn.v-btn--fixed.v-btn--bottom.isIOS
    bottom 90px
</style>
