<template lang="pug">
AppLayout
  template(v-slot:header)
    Search(@scan='dialogRoute("scan")')
  template(v-slot:header-extension, Zv-if='tab == \'catalog\' || tab == \'catalog2\'')
    NavCategories(:hide='!(tab != "home" || tab != "check")', @route='dialogRoute')

  v-window(v-model='tab', continuous)
    v-window-item(value='home'): Home
    v-window-item(value='catalog')
      Catalog(@edit='show.form = true')
    v-window-item(value='catalog2')
      Catalog(img, @edit='show.form2 = true')
    v-window-item(value='catalog3')
      Catalog(col3, @edit='show.form2 = true')
    v-window-item(value='list')
      CatalogList(col3, @edit='show.form3 = true')
    v-window-item(value='check')
      Check(comp)
    //- v-window-item(value="form" :transition="false"): Form
    //- v-window-item(value="calc" :transition="false"): Calc
    //- v-window-item(value="orders" :transition="false"): Orders

  template(v-slot:footer)
    NavBottom(v-model='tab')
    Basket(@click='dialogRoute("cart"); tab = "check"', cart-icon, no-menu)
  //- NavBottomNav(v-model="tab")

  template(v-slot:dialogs)
    NavFabButton(:value='tab == "catalog" || tab == "catalog2"|| tab == "check"', @click='dialogRoute("calc")')
    GroupCatalog(v-model='show.catalog', @route='dialogRoute')
    GroupFormCalc(v-model='show.form2', @route='dialogRoute')
    GroupCheckout(v-model='show.checkout', @route='dialogRoute')
    Scan(v-model='show.scan', @route='dialogRoute')
    Calc(v-model='show.calc', @route='dialogRoute')
    Form(v-model='show.form', @route='dialogRoute')
    //- DialogCart(v-model="show.cart" @route="dialogRoute")
    Cart(
      :fullscreen='fullscreen',
      v-model='show.cart',
      @edit='dialogRoute("form")',
      @input=" tab = 'catalog'"
      @route='dialogRoute',
      @fullscreen='fullscreen = !fullscreen'
    )
    Payment(v-model='show.payment' @input=" tab = 'catalog'" @route='dialogRoute', Zfullscreen, ZZfullscreen='fullscreen = !fullscreen')
    Completed(v-model='show.completed' @input=" tab = 'catalog'" @route='dialogRoute', Zfullscreen, ZZfullscreen='fullscreen = !fullscreen')
    KaspiQR(v-model='show.kaspi', @route='dialogRoute')
    Check(v-model='show.check', @route='dialogRoute', Zapp)
    Client(v-model='show.client', @route='dialogRoute', inset)
    Contacts(v-model='show.contacts', @route='dialogRoute')
    DialogCategories(v-model='show.categories1')
    Categories(v-model='show.categories', fullscreen, hide-overlay, transition='dialog-top-transition')
    //- DialogCheck(v-model="show.check" @route="dialogRoute")
    //- DialogForm(v-model="show.form" @route="dialogRoute")
    //- DialogCart(v-model="show.cart" @route="dialogRoute")
    //- DPageCart(v-model="showCart")
</template>
<script>
import { mapState, mapActions } from 'vuex'
import Basket from 'T@/components/cart/Basket.vue'
import Cart from 'T@/components/cart/Cart.vue'

import Check from 'T@/components/cart/check/Check.vue'
import Payment from 'T@/components/cart/payment/Payment.vue'
import Search from 'T@/components/catalog/Search.vue'
import NavCategories from 'T@/components/catalog/NavCategories.vue'
import NavFabButton from 'T@/components/NavFabButton.vue'
import NavBottom from 'T@/components/NavBottom.vue'
import Catalog from 'T@/components/catalog/Catalog.vue'
import Form from 'T@/components/catalog/Form.vue'
import AppLayout from 'T@/AppLayout.vue'

import TaxpassportPage from 'T@/pages/apps/taxpassport/Page.vue'
import AuthCallback from 'T@/pages/apps/authcallback/AuthCallback.vue'
import Completed from '@/TJTheme/components/cart/payment/Completed.vue'

export default {
  name: 'Kassa',
  components: {
    AppLayout,
    Check,
    Payment,
    Completed,
    Search,
    NavFabButton,
    TaxpassportPage,
    AuthCallback,
    Basket,
    Catalog,
    Cart,
    Form,
    CatalogList: () => import('T@/components/catalog/CatalogList.vue'),
    Scan: () => import('T@/components/Scan.vue'),
    Calc: () => import('T@/components/catalog/Calc.vue'),
    GroupFormCalc: () => import('./GroupFormCalc.vue'),
    GroupCheckout: () => import('./GroupCheckout.vue'),
    GroupCatalog: () => import('./GroupCatalog.vue'),

    Contacts: () => import('T@/components/cart/client/Contacts.vue'),
    Client: () => import('T@/components/cart/client/Client.vue'),
    Home: () => import('T@/components/catalog/Home.vue'),

    DialogCategories: () => import('./DialogCategories.vue'),
    Categories: () => import('./Categories.vue'),
    // DialogForm: () => import('./DialogForm.vue'),
    // DialogCart: () => import('../_tmp/DialogCart.vue'),
    // DialogPayment: () => import('./DialogPayment.vue'),
    // DialogClient: () => import('./DialogClient.vue'),
    // DialogCheck: () => import('./DialogCheck.vue'),
    // DPageCart: () => import('./DPageCart.vue'),
    NavBottom,
    // NavBottomNav: () => import('T@/components/NavBottomNav.vue'),
    NavCategories,
    KaspiQR: () => import('T@/components/cart/payment/KaspiQR.vue'),
  },

  data: () => ({
    tab: 'catalog',
    route: null,
    editMode: false,
    fullscreen: false,
    show: {
      categories: false,
      categories1: false,
      catalog: false,
      checkout: false,
      scan: false,
      calc: false,
      cart: false,
      form: false,
      form2: false,
      check: false,
      payment: false,
      completed: false,
      kaspi: false,
      client: false,
      contacts: false,
    },
    showBottomNav: true,
  }),

  computed: {
    ...mapState({
      cart: (state) => state.tjtheme.cart,
    }),
  },

  methods: {
    ...mapActions({}),
    dialogRoute(route, autoClose) {
      console.log('dialogRoute', route, autoClose, this.route)
      if (autoClose && this.route) {
        this.show[this.route] = false
      }
      this.show[route] = true
      this.route = route
    },
  },
}
</script>
<style lang="stylus"></style>
