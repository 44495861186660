<template lang="pug">
div(style="max-width:100%")
  v-row.wrap.px-1(no-gutters)
    v-flex(:xs4="!!col3" :xs6="!col3" sm3  xl2 v-for="(item, i) in gridCount" :key="i")
      CatalogItem(
        @click="catalog[i]?addItem(catalog[i]):$emit('edit',catalog[i])"
        :img="img"
        @edit="$emit('edit')" :item="catalog[i]"
        :key="i"
        :selected="i==3")
      //- v-responsive(v-if="i === 2" :key="`width-${i}`" width="100%")

    //- CatalogItem(@click="addToCart(item)" :item="catalog[i]" :key="i")
  //- CatalogItem(outlined @click="createItem()")
    v-icon(color="primary" size="40") mdi-plus
  slot
</template>
<script>
import { mapState, mapActions } from 'vuex'
import CatalogItem from './_parts/CatalogItem.vue'

export default {
  components: {
    CatalogItem,
  },
  props: {
    col3: {
      type: Boolean,
      default: false,
    },
    img: {
      type: Boolean,
      default: false,
    },
    gridCount: {
      type: Number,
      default: 12,
    },

  },

  data: () => ({
  }),

  computed: {
    ...mapState({
      catalog: (state) => state.tjtheme.catalog,
      cart: (state) => state.tjtheme.cart,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
      addItem: 'tjtheme/addItem',
    }),
  },
}
</script>
<style lang="stylus">

</style>
