<template lang="pug">
POS(v-if="$vuetify.breakpoint.lgAndUp")
POSBoxed(v-else-if="$vuetify.breakpoint.mdAndUp")
Mobile(v-else)
</template>
<script>
import { mapState, mapActions } from 'vuex'

import POS from './kassa/poslayout/KassaPOSLayout.vue'
import POSBoxed from './kassa/poslayout/KassaPOSBoxedLayout.vue'

import Mobile from './kassa/Kassa.vue'

export default {
  name: 'Kassa',
  components: {
    POS,
    POSBoxed,
    Mobile,
  },

  data: () => ({
    showDialog: false,
    editMode: false,
    discountMarkupValue: 0,
    positionsDialog: false,
  }),

  computed: {
    ...mapState({
      positionsLoading: (state) => state.positions.loading,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus"></style>
