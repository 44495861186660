<template lang="pug">
//- v-expand-transition
v-scroll-y-transition
  .d-flex.flex-no-wrap.justify-space-between.align-center(v-if="!hide")
    v-tabs.filter-tabs(
      style="width:300px"
      v-model="tabs"
      Zcenter-active
      Zshow-arrows
      dark
      height="36")
      v-tab(v-for="c in categories" :key="c.title"  @click="$emit('route','categories1')") {{c.title}}
    v-btn.ml-2(icon color="grey darken-2" x-small @click="$emit('route','categories')"): v-icon mdi-filter
</template>
<script>
import { mapState, mapActions } from 'vuex'

export default {
  components: {
  },
  props: {
    hide: {
      type: Boolean,
      default: undefined,
    },
  },
  data: () => ({
    showCategories: false,
    tabs: 1,
  }),

  computed: {
    ...mapState({
      categories: (state) => state.tjtheme.categories,
    }),
  },

  watch: {},

  methods: {
    ...mapActions({
    }),
  },
}
</script>
<style lang="stylus">

.filter-tabs
  .v-tab
    text-transform: none !important
    letter-spacing: 0
    font-size 13px
    font-weight 300
    padding 0 6px

  .v-slide-group__next, .v-slide-group__prev
    min-width 24px
    flex 0 1 24px

  .v-slide-group__prev
    display none !important
</style>
